import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
// import Slider from "react-slick";

function TextGrid() {
  let pageHeader = React.createRef();
  // const settings = {
  //   dots: true,
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 3000,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   className: "center",
  //   variableHeight: true,
  //   centerMode: true,
  // };
  // const size =
  //   document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;

  return (
    <>
      <div
        style={{
          // backgroundColor: '#ffffff09',
          background: "linear-gradient(142deg, rgba(32,120,183,1) 0%, rgba(51,180,171,1) 100%)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
          backgroundBlendMode: "darken",
        }}
        className="py-5"
        data-parallax={true}
        ref={pageHeader}
      >
        {/* <div className='overlay-primary' /> */}
        <Container>
          <Row>
            <Col className="ml-auto mr-auto " md={12}>
              <h1 className="section-title mb-4 text-700 text-white">
                <b>Unlocking The Future Of Healthcare Through Digital Innovation</b>
              </h1>
              <br />
            </Col>
            <Col lg={8} className="align-self-center">
              <h3 className="text-400  text-white" style={{ opacity: 1 }}>
                The healthcare industry, like most industries across the globe, is undergoing
                tremendous transformation in the dawn of robotics, AI and automation. With the
                introduction of these ground-breaking technologies, humanity is bound to brace
                itself for tomorrow’s healthcare landscape, which will bring forth new concepts,
                technologies, challenges, and unconventional treatment methods.
                <br /> <br /> <br />
                <br /> The health technology (Health-Tech) sector has experienced the most
                substantial growth in combined enterprise value in the MENA region since 2016. The
                current valuation of the health-Tech startup ecosystem in the MENA region exceeds
                $1.5 billion, marking a remarkable 22-fold increase since 2016. The surge in
                Health-Tech startups is evident, having attracted over $200 million in venture
                capital funding since 2016. <br />
                <br /> <br />
                The Future of Healthcare Summit 2024, set to take place on 27<sup>th</sup> November, 2024 in the UAE’s
                progressive capital, Abu Dhabi and under the umbrella of SEHA (Abu Dhabi Health
                Services Company), aims to serve as a platform for healthcare professionals who are
                invested in the future of the healthcare industry. The summit will gather healthcare
                leaders in their attempt to decipher the future challenges and leverage the
                opportunities of tomorrow as they work together to propel the healthcare sector and
                future proof it.
                <br />
                <br />
                <Button className="px-3 py-2 rounded-0" color="primary" href="/register">
                  REGISTER NOW TO ATTEND
                </Button>
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {content.map((c) => (
              <Col lg={c.col} key={c.link} className="align-self-center">
                <a href={c.link} className="text-center">
                  <img
                    src={require(`assets/banners/${c.image}`)}
                    width="100%"
                    // style={{ maxHeight: "300px" }}
                    alt=""
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  // { link: "https://malaffi.ae/", image: "malafi.png", col: 3 },
  // { link: "https://www.iqvia.com/", image: "iqvia.png", col: 3 },
  // {
  //   link: "https://events.intersystems.com/taas",
  //   image: "TAS_728x90.gif",
  //   col: 6,
  // },
];

export default TextGrid;
