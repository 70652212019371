import React from "react";

import { Container, Row, Col, Card, Modal } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import { speakers, pastspeakers, speakers2024 } from "views/content";

function SpeakerGrid() {
  // const size =
  //   document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
  //     ? true
  //     : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    slidesToShow: 4,
    rows: 2,
    slidesToScroll: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <Card
        className="card-background-image mx-auto p-3 text-white"
        style={{
          borderRadius: 0,
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <Container fluid>
          <Row>
            <Col xs={12} className="px-0 text-center">
              <div>
                <img
                  src={`assets/speakers/${speaker.image}`}
                  className="rounded-circle"
                  width="100%"
                  alt="speaker"
                />
              </div>
            </Col>

            <Col xs={12} className="text-center">
              <h3 className="text-700 text-uppercase mt-2 m-0 d-block px-0">{speaker.name}</h3>

              <small className="author m-0">{speaker.title}</small>
              <h4 className="text-700  text-uppercase m-0 d-block px-0">{speaker.company}</h4>
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }

  return (
    <>
      <div
        style={{
          background: "linear-gradient(142deg, rgba(32,120,183,1) 0%, rgba(51,180,171,1) 100%)",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="mb-5">
              <h1 className="text-white text-left section-title text-center">
                <b>SPEAKERS 2024</b>
              </h1>
            </Col>
            {speakers2024.map((speaker) => (
              <Col md={3} key={speaker.name}>
                <SpeakerCard speaker={speaker} />
              </Col>
            ))}

            <Col lg={12} className="mb-5">
              <h1 className="text-white text-left section-title text-center">
                <b>PAST SPEAKERS</b>
              </h1>
            </Col>

            <Col xs={11} className={"mx-auto"}>
              <Slider {...settings}>
                {[...speakers, ...pastspeakers].map((speaker, index) => (
                  <SpeakerCard speaker={speaker} />
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
