import React from "react";

import { Container, Row, Col, Card } from "reactstrap";

import Slider from "react-slick";
import { sponsors, newsponsors } from "views/content";

function Sponsor() {
  // const rows = [
  //   ["LEAD SPONSOR"],
  //   ["STRATEGIC SPONSOR", "GOLD SPONSOR", "CLOUD PARTNER"],
  //   ["NETWORKING SPONSOR", "DATA MANAGEMENT PARTNER", "RPA PARTNER", "DIGITAL HEALTH PARTNER"],
  // ];

  // const newrows = [
  //   ["TECHNOLOGY PARTNER"],
  //   ["ASSOCIATE PARTNER"],
  //   ["NETWORKING PARTNER"],
  //   ["MEDIA PARTNER"],
  // ];
  const settings = {
    dots: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
    slidesToShow: 4,
    slidesToScroll: 4,
    rows: 2,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div
        style={{
          backgroundColor: "e0e4e5",
          // backgroundImage: "linear-gradient(315deg, #d4418e 0%, #0652c5 74%)",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        {" "}
        <Container className="border-top">
          <Row className="justify-content-center">
            <Col lg={12}>
              <h1 className="text-center text-main section-title my-5">
                <b>PAST SPONSORS & PARTNERS</b>
              </h1>
            </Col>

            <Col lg={12} xs={11} className={"ml-auto mr-auto"}>
              <Slider {...settings}>
                {[...newsponsors, ...sponsors, ...associations, ...s_associations].map(
                  (sponsor, index) => (
                    // <Col lg={3} key={index}>
                    <a href={`/sponsors/${sponsor.pageLink}`}>
                      <Container fluid>
                        <Row>
                          {/* <Col
                          lg={12}
                          className="text-center text-dark border-bottom mb-2"
                          style={{
                            padding: "1rem 0rem",
                          }}
                        >
                          <h5
                            className="text-dark text-400 text-uppercase"
                            style={{ margin: 0, padding: 0 }}
                            dangerouslySetInnerHTML={{
                              __html: sponsor.type,
                            }}
                          ></h5>
                        </Col> */}
                          <Col lg={12}>
                            <Card style={{ borderRadius: "0" }}>
                              <img
                                src={require(`assets/sponsors/${sponsor.image}`)}
                                alt={sponsor.name}
                                width="100%"
                                style={{ borderRadius: "0" }}
                              />
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </a>
                    // </Col>
                  )
                )}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;

export const associations = [
  {
    name: "UAE MOHAP",
    pageLink: "UAE MOHAP",
    type: "SUPPORTING ASSOCIATION",
    image: "mhp.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];

export const s_associations = [
  {
    name: "Dubai Healthcare City Authority",
    pageLink: "dhca",
    type: "SUPPORTING ASSOCIATION",
    image: "dhca.png",
    text: [``],
    link: "",
    size: "80%",
    grid: 4,
  },
];
