// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

// import Particles from "../Particles";
import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" +
            require("assets/images/medical-banner-with-doctor-wearing-coat.jpg") +
            ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="page-header"
        data-parallax={true}
      >
        {/* <Anime /> */}

        <Container fluid>
          <Row className="align-items-center">
            <Col lg={6} className="text-center">
              <img
                src="/assets/logoDHF.webp?v=0.03"
                alt="back"
                width="100%"
                className="text-left"
                style={{ maxWidth: "450px" }}
              />

              <Container fluid>
                <Row className="pt-1 justify-content-center">
                  <Col lg={8} xs={8} className={`px-0 ${size ? "" : "pl-3"}`}>
                    <h1
                      className="text-500 text-left text-white ml-3 my-2 text-center"
                      style={{
                        fontSize: size ? "1.6rem" : "1.2rem",
                        textAlign: !size && "center",
                        lineHeight: '2.1rem',
                        border: "1px solid #dedede",
                        borderRadius: "5px",
                        padding: "10px 0px",
                      }}
                    >
                      <span className="ml-4 text-700">
                        27<sup>th</sup> November, 2024
                        <br />
                        Dubai, UAE
                      </span>
                    </h1>
                  </Col>
                </Row>
                <Row className="pt-1 justify-content-center">
                  <Col lg={12} xs={12} className={`px-0 ${size ? "" : "pl-0"}`}>
                    <Button
                      className="py-2 rounded-0"
                      color="primary"
                      // style={{ width: "90%" }}
                      href="/sponsor/download-brochure"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", textTransform: 'none', letterSpacing: '1px' }}>
                        Download Brochure
                      </p>
                    </Button>{" "}
                  {/* </Col>
                  <Col lg={6} xs={6} className={`px-0`}> */}
                    <Button
                      className="py-2 rounded-0"
                      color="info"
                      // style={{ width: "90%" }}
                      href="/register"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", textTransform: 'none', letterSpacing: '1px' }}>
                        Register now
                      </p>
                    </Button>{" "}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
