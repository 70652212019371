export const speakers2024 = [
  {
    name: "Dr. Khulood Mohamed Alsayegh",
    title: "Head of Clinical Standards and Guidelines Section-Health Policies and Standard Department",
    company: "Dubai Health Authority",
    image: "2024/Dr%20Khulood.jpg",
    text: [`Dr. Khulood Alsayegh, MD, is an accomplished healthcare leader and regulatory advisor, Head of 
Clinical Standards and Guidelines with Dubai Health Authority, a non- resident fellow researcher with 
Mohamed Bin Rashid School of Governance and consultant Family Physician. Qualified at the Royal 
College of Surgeons in Ireland and Harvard Medical school, Boston. She has 20 years of clinical 
experience in the United States, Europe, the GCC and the United Arab Emirates. Pioneer in Telehealth 
and AI ethics, certified as the first ethical Lead Assessor in healthcare within the Middle East and Africa, 
by IEEE. Participated as a speaker in multiple local and international conferences. Won multiple 
international awards and is currently leading the ongoing development of clinical standards for 
application to both private and public sectors across the emirate of Dubai. `],
  },
  {
    name: "Dr. Osama Elhassan",
    title: "Head of e-Health Section at Health Data & Information Analysis",
    company: "Dubai Health Authority",
    image: "2024/DrOsama.jpg",
    text: [``],
  },
  {
    name: "Dr. Rasheed Al Hammadi",
    title: "Advisor- Research and Innovation Center",
    company: "Department of Health Abu Dhabi Research and Innovation Center",
    image: "2024/Dr. Rasheed - photo.jpg",
    text: [``],
  },
  {
    name: "Dr. Sara Al Dallal",
    title: "President of Emirates Health Economics Society at Emirates Medical Association",
    company: "Senior Health Service Specialist at the Dubai Health Authority",
    image: "2024/SaraAlDallal.jpg",
    text: [``],
  }
]
export const speakers = [
  {
    name: "Ahmad Awada",
    title: "Virtual Health Director",
    company: "Mediclinic Middle East",
    image: "Ahmed Awada.JPEG",
    text: [
      `Ahmad Awada, Virtual Health Director at Mediclinic Middle East, is a digital health enthusiast
    focusing on population health management. He launched integrated healthcare services like
    MyMediclinic 24x7 and Virtual Clinic. Awada also co-founded and worked as CTO for Mobile Doctors
    24-7 and TruDoc 24x7, developing digital healthcare platforms. With 18 years in IT and healthcare
    development, he created systems for telemedicine and digital health apps. Awada studied
    Innovation in Healthcare and Telehealth at institutions like Harvard X and is pursuing a Master of
    eHealth Management. He&#39;s also an active member in international telemedicine communities.`,
    ],
  },

  {
    name: "John Sunil",
    title: "Chief Executive Officer",
    company: "Burjeel Hospital",
    image: "John Sunil, CEO, Burjeel Holdings (1).jpg",
    text: [
      `Mr. John Sunil is the Group CEO of Burjeel Holdings. He has played a crucial role in Burjeel
    Holdings&#39; transformation into a leading healthcare services provider in the UAE. He is
    responsible for steering the Group&#39;s operations and corporate strategy to strengthen long-term
    value creation.
    Mr. Sunil has over 28 years of finance and operations expertise, with 15 years in healthcare. He
    is a result-driven leader with a proven track record of driving growth, revenue, operational
    performance, and profitability. Since starting his career in Finance at Burjeel Hospital 10 years
    ago, Mr. Sunil has held multiple leadership positions of increasing responsibility, including as
    Chief Operations Officer and Regional Chief Executive Officer under Burjeel Holdin`,
    ],
  },

  {
    name: "Mona Al Ali",
    title: "Head of Health Information Management Section",
    company: "Dubai Health Authority",
    image: "monaali.png",
    text: [],
  },

  {
    name: "Ahmed Al Zahrani",
    title: "Director, Development Unit - HTM, Development & Integration",
    company: "Ministry of National Guard Health Affairs - KSA",
    image: "Ahmed Alzahrani jpeg.jpg",
    text: [],
  },

  {
    name: "Lubna Naeem",
    title: "Director of Operations ",
    company: "Gargash Hospital",
    image: "Lubna.jpg",
    text: [
      `A leader par excellence, who has risen through the corporate rungs through her
    determination, hard work, and leadership capabilities, Mrs. Lubna Naeem is
    currently the Director – Operations &amp; Business Development at Gargash Hospital,
    where she is responsible for hospital operations, driving business, strategic
    planning, forging partnerships, operational P&amp;L, project management, and future
    expansions.
    Known for her excellent communication and presentation skills, she has been a
    keynote speaker or a panelist at various conferences/summits.  Her positivity and
    zeal, together with her ability to inspire, lead &amp; delegate, have not only earned her
    the respect and admiration of her colleagues but also won her various leadership
    and excellence awards/nominations.`,
    ],
  },

  {
    name: "Dr. Samer Ellahham",
    title: "Medical Director of the Continuous Improvement Department",
    company: "Cleveland Clinic Abu Dhabi",
    image: "Sameer ellahhams.jpg",
    text: [
      `Dr. Ellahham is Cleveland Clinic Caregiver, Cleveland, US seconded as Senior
    Cardiovascular Consultant, Medical Director of Continuous Improvement and Director of
    Accreditation in the Quality and Safety Institute in Cleveland Clinic Abu Dhabi. He is
    Clinical Professor of Medicine | Cleveland Clinic Lerner College of Medicine of Case Western
    Reserve University and Gulf Medical University. He is the Middle East Regional Chair,
    Patient Safety Movement Foundation, ISQua Expert, AHA Hospital Accreditation Science
    Committee, Member, ex-Middle East Representative of the JCI Standards Subcommittee
    and American College of Cardiology Accreditation Foundation Board, Member. He is a
    recognized innovative leader and avid researcher in artificial intelligence, blockchain,
    telehealth, metaverse, quality and clinical cardiology. He is a recognized world-leader in
    these fields.`,
    ],
  },

  {
    name: "Muhammed Saqib Chaudhry",
    title: "Chief Information Officer",
    company: "Johns Hopkins Aramco Healthcare",
    image: "Saqib Pic7.jpg",
    text: [
      `Saqib is a Digital Transformation thought leader with over two decades of experience in designing
    and delivering innovative digital solutions for organizations across North America and the Middle
    East. He serves as the Chief Information Officer at Johns Hopkins Aramco Hospital (JHAH) in
    Dhahran, KSA. Prior to joining JHAH, Saqib led the Digital Innovation and Information Security efforts
    at the Cleveland Clinic Abu Dhabi.
    Saqib sits on the advisory board of IBM’s Global Security practice and on the EMEA CIO Executive
    Advisory board for Palo Alto networks. He is currently pursing Executive MBA from the University of
    Oxford. He is a graduate of the FBI CISO Academy and obtained his Cybersecurity Graduate
    Certificate from Harvard University.`,
    ],
  },
  {
    name: "Dr. Ayesha Al Mutawa",
    title: "Director Strategy & Excellence",
    company: "Dubai Corporation for Ambulance Services",
    image: "Dr. Ayesha Pic.jpg",
    text: [],
  },

  {
    name: "David Hadley",
    title: "Chief Executive Officer",
    company: "NMC Healthcare",
    image: "David Hadley- CEO.jpg",
    text: [],
  },

  {
    name: "Raza Siddiqui",
    title: "Group Chief Executive Officer",
    company: "Arabian Healthcare Group",
    image: "Dr Raza Siddiqui, Executive Director , RAK Hospital.jpg",
    text: [],
  },
  {
    name: "Rustom Lawyer",
    title: "Co-Founder & CEO",
    company: "Augnito",
    image: "Rustom Lawyer's_High Res Picture.jpg",
    text: [`
    Rustom is the Co-Founder & CEO of Augnito, an intuitive and advanced Voice AI innovator that is revolutionising clinical documentation and augmenting physician capabilities in the global healthcare market.
<br/><br/>Rustom's commitment to deep tech research & development as well as human-centric design principles has helped establish Augnito as a key player in Clinical Speech Recognition and Natural Language Processing technologies. In a short span of 3 years, the company has amassed over 300 hospitals, across more than 25 countries (including the USA, UK, Canada, Australia, India, UAE, Kingdom of Saudi Arabia). His overarching vision is to help institutions establish a strong backbone for centralised healthcare infrastructure, driven by the adoption and accessibility of Electronic Health/Medical Records (EHR/EMRs).
<br/><br/>A serial entrepreneur in the healthcare industry, Rustom also Co-founded Scribetech (2001) at the age of 19. The company pioneered clinical documentation services within the UK’s National Health Service (NHS) as well as independent healthcare organisations in the region.
<br/><br/>Rustom has received an executive education from Harvard Business School and Stanford University, among others.
    `],
  },

  {
    name: "Abid Shah",
    title: "Group Chief Information Officer",
    company: "Saudi German Health Group UAE",
    image: "Mr. Abid Photo.png",
    text: [],
  },
  {
    name: "Jean Shahdadpuri",
    title: "Co-Founder and Chief Medical Officer",
    company: "Ardent Consulting Services",
    image: "Jean Shahdadpuri.jpeg",
    text: [],
  },
  {
    name: "Dr. Mohaymen Abdelghany",
    title: "Chief Executive Officer",
    company: "Fakeeh University Hospital",
    image: "Dr. Mohaymen Abddelghany.jpg",
    text: [],
  },
  {
    name: "Piyush Bhan",
    title: "SVP International Business",
    company: "Salucro",
    image: "1690062253247.jpg",
    text: [],
  },
  
];

export const pastspeakers = [
  {
    name: "H.E Ahmad AlDashti ",
    title: "Assistant Undersecretary of Support Services ",
    company: "Ministry of Health and Prevention, UAE ",
    image: "aldahsti.png",
    text: [
      `
      H.E Ahmad AlDashti is a Assistant Undersecretary Of Support Services in the Ministry of Health and Prevention, UAE. His
      expertise lies in Cisco IP Telephony Design, ISO Standards, and ISO 27001 Lead Auditor. Mr. Ahmad holds a Higher National
      Diploma in business information technology from Higher Colleges of Technology.
    `,
    ],
  },
  {
    name: "Dr. Younis Kazim",
    title: "CEO",
    company: "Dubai Healthcare Corporation",
    image: "younis.png",
    text: [
      `
      Dr. Younis Kazim is the CEO of Dubai Healthcare Corporation, which was established in May 2018 to manage and operate all government health care facilities in Dubai including four (4) hospitals – Rashid Hospital, Dubai Hospital, Latifa Hospital, Hatta Hospital, 13 Primary Care Centers, 7 Specialized Healthcare Centers and 17 Medical Fitness Centers among other offices and departments under the Corporation.
      <br/><br/>He successfully led the joint efforts at different levels to combat and control the COVID-19 situation in the emirate of Dubai including expansion of existing bed capacities and manpower, fast track construction and operation of new centers and field hospitals as well as command centers. New milestones were achieved during his tenure such as organ transplant, dialysis and rehabilitation services, and the world’s first international healthcare accreditation of all public hospitals in a city.
      <br/><br/>Dr. Younis is a vascular surgeon with more than 20 years of experience, and he holds memberships in the European Society of Vascular Surgery, the European Society of Surgery and Surgical Research, and the German Society of Vascular Surgery. He als  has a honorary British Fellowship in Surgery.
      <br/><br/>He has more than 15 years’ experience in hospital management and is to be a pioneer of vascular medicine service in the UAE.
    `,
    ],
  },

  {
    name: "Dr. Haidar Saeed Al Yousuf ",
    title: "Managing Director ",
    company: "Al-Futtaim Health   ",
    image: "haidar.png",
    text: [
      `
      Dr. Haidar Saeed Al Yousuf is a Managing Director of Al-Futtaim Health. He has recently been named ‘CEO of the Year in the UAE’, at the
      Healthcare Asia Awards 2022, a fitting testimony to his dynamic leadership and tireless efforts in positioning the brand as a distinct
      healthcare offering in its segment.
      <br/><br/>
      He has helped foster an ecosystem built on a unique business model of balance where top-quality service and the best treatment are
      available to everyone in the UAE. It is a moment of special pride, not just for Al-Futtaim Health but also for the UAE, as he is the first
      doctor and CEO from the nation to be honored across the Asian continent.
      <br/><br/>
      Dr. Haidar is well known for the implementation of the Health Insurance System of Dubai (ISAHD) which has set a global benchmark, and
      the mandatory Health Insurance Law for Dubai, which has led to achieving total healthcare coverage for the entire population of the UAE
      in just three years.

    `,
    ],
  },
  {
    name: "Ali	Alajme",
    company: "Ministry of Health & Prevention, UAE",
    title: "Director,  Digital Health",
    image: "alajme.jpg",
    companyImage: undefined,
    text: [
      `
      Ali Alajme is the Director of Digital Health in the Ministry of Health & Prevention, UAE.
<br/>
<br/>
He has an Executive MBA from Higher Colleges of Technology. He graduated from Mohammed Bin Rashid Government
Innovation Center in Association with Cambridge University, UK. Ali Alajme is an alumnus of the Mohammed Bin Rashid
leadership development program in Executive leadership associated with Duke University, and of Government Experience
Exchange Trainers from the UAE prime minister’s office program.
<br/>
<br/>

Having deep knowledge of IT and quality, Ali Alajme worked on big government and national initiatives such as UAE smart
initiative which resulted in bringing UAE to rank six in the Global Competitiveness Report in Smart Government KPI. He was
awarded for providing the best mobile service at the government summit 2015. In the past, he has worked with many
committees such as the AI data committee, Smart Services committee, Arab Health Committee, and a federal committee on
the adoption of the 4th industry generation.
<br/>
<br/>

Ali Alajme was honored as a CIO of the year 2020 by IDC/IDG for his work in technology during the pandemic.
      `,
    ],
  },
  {
    name: "Dr Mohaymen Abdelghany",
    title: "CEO ",
    company: "Danat Al Emarat Hospital ",
    image: "mohaymen.png",
    text: [
      `
      Dr. Mohaymen Abdelghany is the CEO of Danat Al Emarat Hospital for Women & Children, a Mubadala Health
      partner, in Abu Dhabi, UAE. He is also serving as a Physician Consultant at the Joint Commission
      International (JCI)’s world-class expert team of less than 60 global consultants advising hospitals and health
      systems across the globe since 2017.
      <br/><br/>
      He is a highly accomplished senior physician and healthcare executive with more than 25 years of global
      experience.
      <br/><br/>
      Prior to his appointment at Danat Al Emarat Hospital for Women & Children in December 2020, he served
      as the CEO of Dubai-based Al Zahra Hospital from 2014 – 2020. Previously, he has worked as the Executive
      Director of the Healthcare Advisory Board at KEF Holdings, CEO of Saudi German Hospital in Dubai and in
      Abha, KSA, and CEO of Al Sharq Healthcare Group.
      <br/><br/>
      Dr. Abdelghany graduated from Harvard Business School and INSEAD executive leadership programs and
      holds 2 master’s degrees in anesthesia and healthcare management. With his diverse expertise within the
      clinical, managerial, consultancy, quality, technology, and business domains, he has successfully led
      different business transitions in the healthcare sector such as startups, turnarounds, realignments, and
      sustaining success across the region.
      <br/><br/>
      Dr. Abdelghany was selected as Linkedin’s Most Power Profiles CEO in the UAE for 2016, and was awarded
      by Dubai Health Authority and Health Magazine as the UAE’s Best Healthcare Chief Executive in 2018. Dr.
      Abdelghany is a Certified Professional in Healthcare Quality (CPHQ) and Six Sigma Certified. He serves on
      several healthcare and advisory boards in the UAE and the Middle East, and actively participates as a
      speaker/panelist in major international and regional healthcare conferences, symposia and meetings.
    `,
    ],
  },
  {
    name: "Brandon Rowberry ",
    title: "CEO - Digital Health ",
    company: "Aster DM Healthcare ",
    image: "Brandon Rowberry - Pro Photo Picture 2022.jpg",
    text: [
      `
      Brandon Rowberry is the CEO of Aster DM Healthcare - Digital Health. Under Brandon’s leadership, Digital Health was newly set up as an
      independent business and merges both the physical door and digital door to access healthcare, as a seamless experience from simple
      virtual consults to e-ICUS at home. The business will integrate digital transformation, technology, and innovation as core pillars to deliver
      enhanced patient-centric services and enable wider access. Driving the digital roadmap for the entire group of companies he plays a crucial
      role in developing capabilities across the organization’s existing presence across the globe.
      <br/><br/>
      Brandon has led the creation, investment, launch, and growth of dozens of businesses over his career. He currently serves as an advisor
      and board member of a number of companies and Private Equity Firms focused on improving people’s lives around the world.
      <br/><br/>
      Until the end of 2020, Brandon served as an executive in strategy & planning at the Abu Dhabi Investment Authority (ADIA), one of the
      world’s largest Sovereign Wealth Funds. Previous to ADIA, he was an executive leader and significant investor in healthcare, serving as a
      Vice-Chair at the world-renowned Mayo Clinic and as the VP of Innovation Development at United Health Group (the world’s largest
      healthcare company). He was responsible for growing a broad healthcare-focused portfolio as well as a sustainable innovation culture.
    `,
    ],
  },
  {
    name: "Dr. Mohammad Faisal Parvez",
    title: "Chief Operating Officer",
    company: "Thumbay Hospital Ajman",
    image: "faisal.png",
    text: [
      `
      Dr. Mohammad Faisal Parvez is a healthcare management professional with over 15 years of experience working in India and
      the UAE. He holds a Master’s degree in Hospital Administration and currently pursuing a doctoral degree (Ph.D.) from the Tata
      Institute of Social Sciences Mumbai.
      <br/><br/>
      A versatile leader with experience in hospital operations, quality, healthcare IT, business development, and hospital planning &
      strategy. Dr. Mohammed has worked on developing telehealth consultation solutions, and digital transformation in hospitals to
      enhance the patient experience by planning & implementing various solutions like EMR implementation. A few of his Initiatives
      & innovations include telehealth, video consultation, drive-thru pharmacy, well-wishers’ stakeholders’ forum, and patient safety
      digital alert display.
      <br/><br/>
      Dr. Faisal has successfully achieved JCI Accreditations, Sheikh Khalifa Excellence Award for the organization, and also received
      the Professional Excellence Award by ANEXAS Global Excellence awards 2021. He is currently serving as the Head of Business
      development for Thumbay Hospitals in UAE, COO for Thumbay Hospital Ajman, and Lecturer in the College of Healthcare
      Management & Economics at Gulf Medical University. Earlier, he has worked with 750 bedded Kokilaben Dhirubhai Ambani
      Hospital Mumbai and various Thumbay Hospitals &Centers in Sharjah, Fujairah, and Thumbay University Hospital Ajman as COO.
      <br/><br/>
      Dr. Faisal firmly believes that the future of healthcare will be driven by access, decentralization, mobility, a patient-centric
      approach, AI, personalized care, and most importantly, CRM enhancing every touchpoint of the Patient journey.
    `,
    ],
  },
  {
    name: "Dr Sara Al Dallal ",
    title: "Senior Health Service Specialist ",
    company: "Dubai Health Authority ",
    image: "sara.png",
    text: [
      `
     The President of Emirates Health Economics Society at Emirates Medical Association United Arab Emirate is a Medical Doctor from the UAE University College of Medicine and Health Sciences.
<br/><br/>
She completed her internal medicine residency at Dubai Health Authority and holds a master’s in Health Care Management from the
Royal College of Surgeons, Ireland. She currently holds the position of Senior Health Service Specialist in the Insurance Policy
Development Section at the Dubai Health Authority
`,
    ],
  },
  {
    name: "Himanshu Puri ",
    title: "Head of Information Technology ",
    company: "Kings  College Hospital London ",
    image: "himanshu.png",
    text: [
      `
      Himanshu Puri is the Head of IT at King’s College Hospital London, UAE, and has 15 years of global experience in healthcare information
      technology, working with private and public sectors across the USA, UK, India, and the Middle East. Himanshu holds an Executive
      Education Certification from Harvard University and an M.B.A in Strategy from the University of Illinois, Chicago.
      <br/><br/>
      Himanshu started his career as an intern with EMR development at a Fortune 500 company and worked through the corporate ladder to
      lead 85 associates from diverse technical and project management backgrounds. During these years he gained an extensive
      understanding of all aspects of IT from software development, hosting, implementing, consulting, and sales.
      <br/><br/>
      After becoming a healthcare domain expert, he took up responsibilities of designing and change management of technology solutions to
      enhance the healthcare digitization of care provider groups and quickly became an influencer in the industry. Some of his current
      achievements are in the space of Data Analytics with predictive models, AI and Telehealth.
    `,
    ],
  },
  {
    name: "Dr Ahmad Salah ",
    company: "Prime Healthcare Group ",
    title: "Director, Corporate Medical Services ",
    image: "ahmad1.png",
    text: [
      `
      Dr. Ahamad Salah is a renowned practicing dentist with more than 20 years of experience in dental practice and healthcare management.
<br/><br/>
      In addition to his responsibility for the corporate’s medical services and business development, he is Co-Director of Prime Digital, the
      vertical that is responsible for the digital transformation of the organization’s processes.
      <br/><br/>
      Dr. Salah is a six sigma certified black belt professional. Under his leadership as the Chair of the Quality Committee, the group has
      acquired many awards, including the Dubai Quality Appreciation Program Award, JCI accreditation, and ACHS accreditation for telehealth.
      <br/><br/>
      Dr. Salah also worked at the Ministry of Health and Population of Egypt before joining the Prime Healthcare Group.
    `,
    ],
  },

  {
    name: "Morad Qutqut",
    title: "Chief Customer Innovation Officer, Strategic Customer Engagement",
    company: "Hewlett Packard Enterprise",
    image: "morad.png",
    text: [
      `Morad Qutqut is CCIO for the World Wide Strategic Customer Engagement organization leading an integrated customer engagement process that translate customer business aspirations into digital innovations and support customer’s overall vision. Bringing industry experience, subject matter expertise and an echo system of innovated partners together following a state of art, well-defined innovation methodology to convert dreams into reality. 
      `,
    ],
  },
  {
    name: "Abid Hussain Shah",
    title: "Group Chief Information Officer",
    company: "Saudi German Health Group UAE",
    image: "Mr. Abid Photo.png",
    text: [
      "Abid is a Senior Technology leader with over 20 years’ experience in working on some on the largest technology projects globally with organisations at board level across several sectors including, Healthcare, Oil & Gas, Infrastructure, Construction, Facility Management Automotive, Manufacturing and Financial Sectors, with leading FTSE 100 and Fortune 500 companies. Abid has been significantly involved in the performance of technology projects in Europe, Middle East, North America and Asia, having extensive experience in running major technology functions and managing digital transformation programmes.",
      "Before moving to Industry Abid was a Director and Sector Leader within KPMG’s UK Technology Consulting business, leading global accounts including General Motors, Jaguar Land Rover, British American Tobacco and Royal Dutch Shell, advising the board on IT Strategy, Governance Risk & Compliance and Digital Transformation. Utilising technologies including IoT, AI, Digital Twin, Machine Learning and Data Analytics to help transform the operations of the business.",
    ],
  },
  {
    name: "Freddy Jiménez  Gómez",
    title: "Regional Commercial Lead Africa and Middle East | Senior Director",
    company: "Pfizer Biopharmaceuticals Group",
    image: "FREDYC.jpg",
    text: [
      "Forward-thinking Senior Director with a record of devising strategies and leading teams through strategic business plan and digital transformation. More than ten years of diverse experience with Six years in LatAm, one year with the global team, and three years as a Regional Commercial Lead in the Middle East and Africa. Harvard Alumni with passion for Artificial Intelligence and currently partaking in the AI Professional Program at MIT and the AI Executive Program at MBZUAI (Mohamed Bin Zayed University of Artificial Intelligence)",
    ],
  },
  {
    name: "Ahmed Al Hajeri",
    title: "Chief Executive Officer",
    company: "National Ambulance UAE.",
    image: "000 AHMED AL HAJERI - white.jpg",
    text: [
      `
      Throughout his career, Ahmed Al Hajeri has demonstrated his leadership skills and commitment to
serving the country in the critical emergency health sector. His experience of a serious traffic accident
was pivotal in setting his dedication to improving the level of pre-hospital care in the United Arab
Emirates and made him a strong advocate of and educator for providing Paramedicine and Emergency
Service management.<br/><br/>
In 2018 he became CEO of National Ambulance after serving as Deputy CEO since 2010. In his current
role, Mr. Al Hajeri has been central to the establishment and development of National Ambulance and
of the ambulance service in the Northern Emirates; leading the organisation through a period of
expansion and major change. Through his leadership style he has demonstrated a capacity to respond to
challenges from across the ranks with both courage and reasoned argument; making a difference where
it matters.<br/><br/>
Through his exemplary knowledge of emergency medicine and planning, as well as his strong ability to
influence at a governmental level, he demonstrates his skills not only as a leader of National Ambulance,
but also across the emergency care service, such as the development and implementation of UAE
clinical practice guidelines.<br/><br/>
Between 2008 and 2011, Ahmed served as Head of Ambulance within Abu Dhabi Police where he
developed a mechanism to professionally license ambulance crew by the Department of Health Abu
Dhabi.<br/><br/>
His leadership qualities and contribution to change has long been recognised and was acknowledged
by Deputy Prime Minister and Minister of Interior of the United Arab Emirates, His Highness Lieutenant
General Sheikh Saif bin Zayed Al Nahyan, who in August 2010, approved the formation of the National
Ambulance Service.<br/><br/>
Ahmed is a member of the Journal of Emergency Medical Services International Editorial Board and the
National Association of EMS Educators. He is also a member of Pan Asian Resuscitation Outcomes Study
(PAROS) and Pan Asian Trauma Outcomes study (PATOS). He holds a Bachelor of Health Science from
Victoria University, Australia and an Executive Master of Healthcare Administration from Zayed
University, UAE. He also holds a PhD Honoris Causa (Doctor of Health Studies) from Charles Sturt
University. Since 1997, he worked in the medical field, working within hospitals and researching,
developing and establishing emergency pre-hospital care in the United Arab Emirates.
      `,
    ],
  },

  {
    name: "Sandeep Sinha",
    title: "Head of Healthcare Consulting – MEA",
    company: "JLL",
    image: "Sandeep-Sinha_Colour_HighRes-Close.jpg",
    text: [``],
  },

  {
    name: "Dr. Hamed Al Hashemi",
    title: "Advisor to the Chairman & Digital Health Lead",
    company: "Department of Health, Abu Dhabi",
    image: "Dr. Hamed pic.jpg",
    text: [
      `
    Dr. Hamed Al Hashemi is currently an Advisor to DOH
Chairmen and a Digital Health Program Lead at Department of
Health (Abu Dhabi). He is leading digital healthcare
transformation programs including data lake for healthcare,
accelerated adoption of artificial intelligence, building big data
analytics capacity, health information exchange, AI &amp;
Innovation Lab and foresights in healthcare. Previously, he
served as Executive Director for Strategic Affairs Sector in
Department of Health, Director for Government Strategy and
Performance for General Secretariat of Executive Council of
Abu Dhabi Government and a Director for Economic Policy
Planning for Abu Dhabi Council for Economic Development. He
started his career in the oil and gas industry with Abu Dhabi
Gas Liquefaction Company Ltd. (ADGAS) where he headed the
development, planning and engineering divisions.<br/><br/>
He holds a master’s in public administration from Harvard
Kennedy School and a doctorate in Business Administration
from Cranfield University.
    `,
    ],
  },

  {
    name: "Mubaraka Ibrahim	",
    company: "Ministry of Health and Prevention",
    title: "Director",
    image: "mubaraka.jpg",
    companyImage: undefined,
    text: [
      `Currently the Director of IT for The Ministry of Health and
    Prevention, Mubaraka Ibrahim is, in many ways, the face
    of Healthcare IT – not only in The UAE but in the Arab
    region in general. Possessing an impressive career track
    record, spanning over 20 dynamic years, and affiliating
    with many world renowned IT organizations, she has
    participated as keynote speaker in endless conferences,
    exhibitions and discussions pertaining to the imperative
    role IT plays in today’s healthcare.
    Possessing a bachelor’s degree in Computer Science from
    Kuwait University and Master Degree in Information
    Management systems from The American University in
    Washington along with a Healthcare Leadership diploma
    from The Harvard School of Public Health, Mubaraka’s
    passion in the relationship between Health and
    Information Technology is easily recognized. Her key
    involvement in the multiple national healthcare technology
    projects, such as Riayati (National unified medical Record)
    Track & Trace, are just a few of many on her resume – she
    wasn’t named in the top 50 CIO and top Female Executives
    Middle East for no reason – these are just a few accolades
    to mention.`,
    ],
  },
  {
    name: "Dr. Hamed	Ali Al-Hashemi	",
    company: "Director of strategy",
    title: "Department of Health, Abu Dhabi",
    image: "hamed.png",
    companyImage: undefined,
    text: [
      `He is currently a Director of Strategic Affairs at Department of Health
    (Abu Dhabi) overseeing the healthcare strategic planning and
    performance management. He is also leading digital healthcare
    transformation programs including accelerated adoption of artificial
    intelligence, building big data analytics capacity, heath information
    exchange, AI &amp; Innovation Lab and foresights in healthcare.
    Previously, he served as Director for Government Strategy and
    Performance at General Secretariat, Executive Council of Abu Dhabi
    Government. He also worked as the Director of Economic Policy
    Planning at Abu Dhabi Council for Economic Development. He started
    his career in the oil and gas industry with Abu Dhabi Gas Liquefaction
    Company Ltd. (ADGAS) where he headed the development, planning
    and engineering divisions.
    His expertise spanning strategy formulation, policy-making, digital
    transformation, outcome and result based performance
    management, design thinking, scenario plannin, project management,
    and building institutional capabilities.
    He holds a master’s in public administration from Harvard Kennedy
    School and a doctorate in Business Administration from Cranfield
    University.`,
    ],
  },
  {
    name: "Ayesha Al Falahi",
    company: "Dubai Healthcare City Authority",
    title: "Senior Director, Professional Licensing",
    image: "ayesha.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: "Ahmad	Yahya	",
    company: "American Hospital Dubai",
    title: "Chief Operating officer",
    image: "ahmad.png",
    companyImage: undefined,
    text: [
      `With over 25 years of experience, Ahmad F. Yahya is a senior IT professional who has worked across the
    USA and the Middle East and holds a Master’s degree in computer science from Western Kentucky
    University, 1990. A dynamic, success-oriented leader and a team builder, Yahya is reputed for delivering
    technology solutions and creating an amicable IT ecosystem for organizations to function optimally. He
    also has experience in handling large IT departments along with advising and counseling departments
    for better operations and implementations of tech-strategies.
    
    Currently working as the Chief Operation Officer at American Hospital, Yahya delivers and oversees the
    IT strategy with a focus on digital transformation that enables innovation, growth, and efficiency, along
    with assessing the internal technological capabilities. Earlier, he has worked with UAE Medical Service
    Corps, Sheikh Khalifa Medical City, Abu Dhabi, and Dubai Healthcare City. In the USA, he has worked
    with El Paso Corporation, Houston, and Scientific Software Inter-comp Houston.`,
    ],
  },

  {
    name: "Aliasgar	Bohari",
    company: "Zulekha Hospitals	",
    title: "Senior Director  Information Technology",
    image: "aliasger.png",
    companyImage: undefined,
    text: [
      `A self-confessed networking wizard, Mr. Aliasgar
    is by qualification an Industrial Electronics
    Engineer who did his specialization in Hardware &
    Networking. Having spent most of his professional
    career spanning over13 years in Information &
    Communication Technology, Mr. Aliasgar is a
    dynamic innovator who strives continuously to
    improve the contribution of IT in healthcare from
    the usual supportive role to an operational
    business tool.<br/>
    Mr. Aliasgar was part of the original team
    responsible for designing and implementing the
    HMIS in 2004 for
    Zulekha Hospitals. A hands-on professional, he
    was instrumental in the 24-month implementation
    from the onset at both multi-specialty hospitals in
    Dubai and Sharjah. This HMIS module was soon
    augmented with access for the four medical
    centers thereby completely integrating the
    Zulekha Healthcare Group in a single seamless
    network.<br/>
    Another important IT upgrade coordinated by Mr.
    Bohari was to implement a custom-designed IT
    Infrastructure in 2005 that complemented and
    catered to the total healthcare needs of Zulekha
    Healthcare Group. Mr. Bohari was also at the core
    of designing and implementing the paperless
    ‘Intranet’ solution for both multi-specialty facilities
    in 2006 at Dubai and Sharjah and also a website
    for patient’s to know more about hospital and
    events, to book an online appointment, to view
    online patient’s reports.`,
    ],
  },
  {
    name: "Atif	Al Braiki",
    company: "Chief Executive Officer ",
    title: " Abu Dhabi Health Data Services LLC (Malaffi)",
    image: "atif.jpg",
    companyImage: undefined,
    text: [
      `As Chief Executive Officer of Abu Dhabi Health Data Services LLC, Mr. Al Braiki played an
    instrumental part in the establishment and implementation of Malaffi, the Abu Dhabi Health
    Information Exchange (HIE). Under his leadership, Malaffi became known as one of the fastest
    rolled-out HIEs globally and the first platform of its kind in the Middle East.<br/>
    Prior to joining Malaffi, Mr. Al Braiki was the Director of Strategic Projects at Injazat. He was
    responsible for developing and incubating strategic public-private partnership (PPP) projects with
    key government clients, in addition to defining business models that satisfied client requirements
    and building relevant business cases, partnerships, and financial models. During this time, he
    undertook the role of General Manager of Hassantuk, a PPP between the Ministry of Interior and
    Injazat.<br/>
    Previously, Mr. Al Braiki held various roles in Zakum Development Company and was Applications
    Group Manager at C4 Advanced Solutions (C4AS). Within this role he led software development
    delivery capabilities for one of the company’s main managed services accounts, in addition to
    overseeing and ensuring the successful delivery of various software development projects. <br/>
    Mr. Al Braiki holds a Master&#39;s degree in Computer Engineering and completed a Bachelor’s degree
    in Computer Engineering and Information Management and Technology from Syracuse University,
    New York.`,
    ],
  },

  {
    name: "Augustine	Amusu	",
    company: "Mediclinic City Hospital",
    title: "Chief Information Officer",
    image: "augutine.png",
    companyImage: undefined,
    text: [],
  },

  {
    name: "Baha	Sayiner	",
    company: "Medcare Hospital	",
    title: "General Manager- IT",
    image: "baha.jpg",
    companyImage: undefined,
    text: [],
  },

  {
    name: "Jaleel	Rahiman",
    company: "Prime Healthcare Group",
    title: "Director of IT and Prime Digital",
    image: "jaleel.jpg",
    companyImage: undefined,
    text: [
      `Mr Jaleel Rahiman is an IT leader with over 24 years of experience in diverse organizations, and has
    expertise in IT Service Management, Change Management, Project Management, Team
    Management, Solutions Delivery, and IT infrastructure design.
    He holds a Bachelor of Science in Computing from the University of Portsmouth and Master of IT
    Management from the University of Wollongong.
    Currently heading the IT division of Prime Healthcare Group, Mr Jaleel has also served in senior
    positions at GlaxoSmithKline (GSK) and Abu Dhabi National Insurance Company (ADNIC).
    He is a collaborative leader with a keen vision to understand business challenges and opportunities,
    cultivate effective communications, and influence business strategy and direction. He guides his
    teams to deliver reliable, scalable, and secure IT services and solutions aligned with both defined
    and emerging requirements.`,
    ],
  },

  {
    name: "Ramakrishnan	Natarajan",
    company: "Emirates Hospitals Group",
    title: "Vice President -  Information Technology",
    image: "rama.jpg",
    companyImage: undefined,
    text: [
      `An IT thought leader with over 16 years of experience in various spheres of Solution Delivery, Operations
    Management, Enterprise Architecture, Information Security within multiple industry sectors including
    Healthcare, System Integrators, Aviation, Banking and Government. Currently he is spearheading the IT
    Vertical for Emirates Hospitals Group including 2 hospitals, around 25+ clinics, pharmacies, day-surgery
    centers and rehabilitation facilities with focus on Strategic Planning and initiatives, Organizational
    Development, IT Roadmap and Infrastructure Planning, Application modernization, ROI-Focused Rapid
    Large-Scale Deployments and System Architectures Modernization among others.`,
    ],
  },
  {
    name: "Robert Long",
    company: "InterSystems",
    title: "Business Development Manager, Middle East ",
    image: "robert.jpg",
    companyImage: undefined,
    text: [
      `Robert is a health Informatics expert with over 30 years of experience in the healthcare
    industry, both from the perspective of a practitioner to that of a healthcare information system
    executive. Robert has more than 23 years wealth of experience within the Middle East region
    mostly with InterSystems. This vast experience has positioned him to clearly understand the
    unique requirements of the Middle East healthcare eco-system.
    Robert also has a strong clinical background in multidisciplinary nursing and prior experience in
    the pharmaceutical industry which equipped him with an in-depth knowledge of the must-have
    requirements of the healthcare sector in today’s advanced technology era.`,
    ],
  },

  {
    name: "Sebastien 	Dallais",
    company: "EMEA Healthcare Market Director",
    title: "Nutanix",
    image: "sebastian.jpg",
    companyImage: undefined,
    text: [
      `Sebastien has a 25 years background in the IT industry with the last 15 focusing with passion on
    Healthcare IT. Sebastien balanced his career between International roles at IT Infrastructure Providers
    and Clinical Software Director positions at leading PACS and EMR companies. He achieved a Data
    Science degree before joining Nutanix in 2018, since then looking after Healthcare markets across
    Europe Middle East and Africa. His engagements in GCC led him to sebasti build a humble but growing
    Oud Oils and woods collection over time.`,
    ],
  },

  {
    name: "Veneeth	Purushotaman	",
    company: "Aster DM Healthcare	",
    title: "Group Chief Information Officer",
    image: "vineeth.png",
    companyImage: undefined,
    text: [
      `Mr. Veneeth has over two decades of experience in leading technology enablement and transformation across Retail, Education
    and Healthcare sectors. Before joining the Healthcare sector in 2016, he has worked in the Retail sector for over 16 years driving
    large technology transformation projects working with companies like Bharti Retail and Shoppers Stop group.
    An ISB-Kellogg School of Management alumni and a Certified Healthcare CIO (CHCIO), has also been instrumental in setting up the
    CHIME India chapter and serves as a board member and joint secretary.
    He is a CIO Hall of Fame honoree and was conferred the Images Most Admired CIO of the Year – Retail in 2015 by the Images Retail
    organization. He is also awarded India’s Top 50 CIOs by CIO Association of India.`,
    ],
  },
  {
    name: "Dr. Mohammad  	Al Redha",
    company: "Dubai Health Authority ",
    title: "Director, Health Informatics & Smart Health",
    image: "redha.jpg",
    companyImage: undefined,
    text: [
      `Dr. Al Redha is a graduate and postgraduate of the Royal College of Surgeons in Ireland and
    holds a Masters Degree (MSc) in Healthcare Management. He Chairs the Emirates Health
    Informatics Society, a scientific and educational platform dedicated for promoting excellence
    in Heath Informatics within the Emirates Medical Association. He is the Director of the
    Health Informatics and Smart Health Department at the Dubai Health Authority. In 2016, he
    also established the DHA’s Accelerator Office within Dubai Future Foundation. Dr.
    Mohammad was a Research Fellow in Clinical Informatics at the Division of Clinical
    Informatics, Harvard Medical School in Boston (2008-2009). Prior to joining the field of
    informatics, Dr. Mohammad was the Chief Operating Officer at Rashid Hospital – a 600 bed
    trauma facility serving Dubai since 1973. He is also a member of the Mohammed Bin Rashid
    Program for Leadership Development Programme and was a Fellow at the Dubai School of
    Government.`,
    ],
  },
  {
    name: "Dr. Mariam  	Al Jalahma",
    company: "Chief Executive Officer",
    title: "National Health Regulatory Authority, Bahrain",
    image: "mariam.jpg",
    companyImage: undefined,
    text: [
      `Appointed by His majesty the King of Bahrain, in 2015 as the Chief Executive Officer of the
      National Health Regularity Authority in the Kingdom of Bahrain. Member of supreme
      Council of Health, Member of the National Task Force for competing Covid19.`,
    ],
  },
  {
    name: "Ahmed AlZahrani",
    company: "Ministry of National Guard Health Affairs - Saudi Arabia",
    title: "Director of Healthcare Technology Development",
    image: "ahmed.jpg",
    companyImage: undefined,
    text: [
      `Ahmed AlZahrani, Director of Healthcare Technology Development - Ministry of National Guard Health
    Affairs in Saudi Arabia, he works closely with multidisciplinary team of healthcare providers to address
    the workflow challenges, and transform it to an improvement opportunity, towards digitizing and
    automating the processes to an innovative business model.
    Currently; he is MBA candidate in Prince Mohammed Bin Salman College for Business &amp;
    Entrepreneurship, he has a bachelor degree in Biomedical Technology with more than 13 years’
    experience in Healthcare IT solutions, He is certified with several credentials such as CPHIMS, PMP, and
    Lean Six Sigma Black Belt.`,
    ],
  },

  {
    name: "Mohammed Al Tamimi",
    company: "Rubrik",
    title: "Regional Director",
    image: "mohammed.jpg",
    companyImage: undefined,
    text: [
      `Mohammed Al Tamimi is the Regional Director and responsible for the Go-To-Market
    operation in the Middle East and Africa region. 
    In his 20 years of career his core responsibilities included diverse roles spanning sales,
    channel and operations with major technology vendors globally. Prior to Rubrik, he was with
    Palo Alto Networks in the role of Regional Sales Manager covering the Gulf region, prior to
    that was at Commvault as Regional Sales and Channel Manager for MENAT
    region.  Mohammed is a recognized technology leader known for his strategic approach on
    how to achieve business goals by leveraging a balanced strategic relations between
    partners and customers and is seen at IT related events and conferences as a key speaker.`,
    ],
  },
  {
    name: " Ravi Akella",
    company: "Iqvia",
    title: "Senior Director, Digital/ Technology, Middle East",
    image: "akella.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: "Dinesh  Chandra",
    company: "Automation Anywhere",
    title: "Vice President Sales",
    image: "Dinesh.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: " Dr. Osama  Elhassan ",
    company: "Dubai Health Authority",
    title: "Specialist, Health Informatics & Smart Health Department",
    image: "osama.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: " Dr. Mazin  Gadir ",
    company: "Iqvia",
    title: "Director, Partnerships & Strategic Alliances",
    image: "mazin.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: "Jonathan Murden",
    company: "Citrix",
    title: "Lead Healthcare Technologist UK&I",
    image: "Jonathan.jpg",
    companyImage: undefined,
    text: [
      `Before joining Citrix Jon worked for Portsmouth Hospitals NHS Trust for over 7 years, as Head of IT
    Infrastructure and Lead Infrastructure Architect responsible for the User Experience transformation
    project.  Jon led the business analysis, process mapping, technical design, and delivery of the Trusts
    flagship end user workspace solution ‘idesktop’ which has been delivered to a hospital of over 5000
    devices and supporting over 8000 staff. The solution provided a targeted end user workspace capable of
    providing the following benefits: Consistent Application delivery, Application workflow and data
    management, a logon time of less than 20 seconds with Tap ‘n’ go workspace access using ID badges. A
    time saving of up to 2 hours per clinician per day. Secure, Persistent desktops, with access from any
    device to any application, anywhere.`,
    ],
  },
  {
    name: "Geetika  Sood ",
    company: "Alpha Data",
    title: "Manager, IOT & Big Data-Analytics Practice",
    image: "geetika.jpg",
    companyImage: undefined,
    text: [``],
  },
  {
    name: "Marwan Abdulaziz Janahi",
    company: "Dubai Science Park",
    title: "Managing Director",
    image: "marwan.jpg",
    companyImage: undefined,
    text: [``],
  },
];

export const sponsors = [
  {
    name: "InterSystems",
    pageLink: "InterSystems",
    type: "STRATEGIC SPONSOR",
    image: "intersystems.jpg",
    text: [
      `Established in 1978, InterSystems provides innovative data solutions for organizations with critical information needs in the healthcare, finance, and logistics sectors and beyond. Our cloud-first data platforms solve interoperability, speed, and scalability problems for organizations around the globe. InterSystems also develops and supports data management in hospitals through the world’s most proven electronic medical record, as well as unified care records for health systems and governments through a powerful suite of healthcare data integration solutions. The company is committed to excellence through its award-winning, 24×7 support for customers and partners in more than 80 countries. Privately held and headquartered in Cambridge, Massachusetts, InterSystems has 25 offices worldwide.`,
    ],
    link: "https://InterSystems.com",
    size: "100%",
    grid: 3,
  },

  {
    name: "HPE",
    pageLink: "HPE",
    type: "GOLD SPONSOR",
    image: "hpe.jpg",
    text: [
      `Hewlett Packard Enterprise (NYSE: HPE) is the global edge-to-cloud company that helps organizations accelerate outcomes by unlocking value from all of their data, everywhere. Built on decades of reimagining the future and innovating to advance the way people live and work, HPE delivers unique, open and intelligent technology solutions as a service.  With offerings spanning Cloud Services, Compute, High Performance Computing & AI, Intelligent Edge, Software, and Storage, HPE provides a consistent experience across all clouds and edges, helping customers develop new business models, engage in new ways, and increase operational performance.`,
    ],
    link: "http://www.hpe.com/",
    size: "100%",
    grid: 3,
  },
  {
    name: "MDS Computers",
    pageLink: "MDS",
    type: "GOLD SPONSOR",
    image: "mds.jpg",
    text: [``],
    link: "https://mdscomputers.ae/",
    size: "100%",
    grid: 3,
  },
  {
    name: "Iqvia",
    pageLink: "Iqvia",
    type: "GOLD SPONSOR",
    image: "iqvia.png",
    text: [``],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "Citrix | Alpha Data",
    pageLink: "citrix",
    type: "LEAD SPONSOR",
    image: "citrix.jpg",
    text: [``],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "Nutanix",
    pageLink: "Nutanix",
    type: "CLOUD PARTNER",
    image: "nutanix.png",
    text: [``],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "Malafi",
    pageLink: "Malafi",
    type: "DIGITAL HEALTH PARTNER",
    image: "malafi.png",
    text: [
      `Malaffi - the Abu Dhabi Health Information Exchange, is the Middle East region’s first Health
    Information Exchange platform that will securely connect all public and private healthcare
    providers in the Emirate of Abu Dhabi to create a unified patient record and to improve
    healthcare quality and patient outcomes.<br/>
    Malaffi is a strategic initiative of the Department of Health Abu Dhabi and is a key
    component in the digital transformation of the healthcare system in Abu Dhabi. 
    With universal, Emirate-wide participation of facilities and patients, Malaffi will facilitate the
    meaningful real-time exchange of health information between healthcare providers,
    healthcare professionals, and government authorities.<br/>
    Providing instant access to the patient’s medical history, Malaffi will enhance the transition of
    care and care coordination, reduce overutilization and duplication of tests and procedures,
    and will enable the practice of precision medicine.<br/>
    As a centralized database of robust population health information, Malaffi will inform and
    drive the DOH’s public health initiatives for a healthier Abu Dhabi.`,
    ],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "Rubrik",
    pageLink: "rubrik",
    type: "DATA MANAGEMENT PARTNER",
    image: "rubrik.png",
    text: [``],
    link: "",
    size: "100%",
    grid: 3,
  },
  {
    name: "Automation Anywhere",
    pageLink: "aa",
    type: "RPA PARTNER",
    image: "aa.png",
    text: [``],
    link: "https://www.automationanywhere.com/",
    size: "100%",
    grid: 3,
  },
  {
    name: "KeyReply",
    pageLink: "keyreply",
    type: "NETWORKING SPONSOR",
    image: "keyreply.jpg",
    text: [
      `
    KeyReply is an industry-defining patient engagement platform that transforms healthcare
delivery processes into seamlessly orchestrated, and virtualized care centered on each
patient’s needs. Our platform unifies patient communication, artificial intelligence and
healthcare systems across every healthcare workflow - improving patient experiences,
maximizing outcomes and reducing care costs.
<br/><br/>
KeyReply has been recognized as Gartner Cool Vendor 2021 in “Conversational and NLT
Widen Use Cases, Domain Knowledge and Dialect Support&quot; report. KeyReply’s platform has
also been accredited by IHiS, the technology agency for the public healthcare sector in
Singapore. Our clients include Singapore General Hospital, Parkway Hospitals, EPF
Malaysia, AIA Singapore and many more across south east asia (SEA).
<br/><br/>
We are also a WhatsApp Business Solution Provider, enabling ISVs globally to deliver
omnichannel solutions to their customers.
<br/><br/>
Some examples of our work include:<br/>
- Care navigation according to common conditions<br/>
- Remote health monitoring communications for chronic diseases<br/>
- Facilitating patient self-service across healthcare institutions such as primary care, acute
hospitals and specialist centers
    
    `,
    ],
    link: "https://keyreply.com/",
    size: "100%",
    grid: 3,
  },
];

export const newsponsors = [
  {
    name: "Augnito",
    pageLink: "Augnito",
    type: "TECHNOLOGY PARTNER",
    image: "Augnito Logo-01.png",
    text: [``],
    link: "https://augnito.ai/",
    size: "100%",
    grid: 3,
  },
  {
    name: "Zebra",
    pageLink: "Zebra",
    type: "ASSOCIATE PARTNER",
    image: "Zebra_Logo_K.png",
    text: [``],
    link: "https://www.zebra.com/ap/en.html",
    size: "100%",
    grid: 3,
  },
  {
    name: "FUH",
    pageLink: "FUH",
    type: "ASSOCIATE PARTNER",
    image: "fakeeh.png",
    text: [``],
    link: "https://www.fuh.care",
    size: "100%",
    grid: 3,
  },
  {
    name: "DCS",
    pageLink: "DCS",
    type: "NETWORKING PARTNER",
    image: "dcs.png",
    text: [``],
    link: "https://dcsme.com/",
    size: "100%",
    grid: 3,
  },
  {
    name: "EMB",
    pageLink: "EMB",
    type: "NETWORKING PARTNER",
    image: "EMB_logo.png",
    text: [``],
    link: "https://emb.global/",
    size: "100%",
    grid: 3,
  },
  {
    name: "ArabMedicare.com",
    pageLink: "ArabMedicare.com",
    type: "MEDIA PARTNER",
    image: "arabmedicare.png",
    text: [
      `ArabMedicare.com, launched on 26 March 1999, was the first online portal designed to serves as a
    regional media and news outlet aimed at healthcare professionals in Arab countries.<br/><br/>
    ArabMedicare.com is now a leading point of reference for delivering the latest medical news, exclusive
    interviews, and information covering important conferences, exhibitions, and expert insights. Moreover,
    ArabMedicare.com provides in-depth content, analyses, and reporting so that our readers can better
    understand how current changes and trends within the medical sector will impact them, their
    organization, and the region.<br/><br/>
    ArabMedicare.com also offers regional-specific news and content on over 30 medical specialty fields to
    include the latest business, technology, and finance headlines. Our goal is to provide high quality
    content and reporting that can support the informational needs of healthcare professionals in the GCC
    and MENA region.`,
    ],
    link: "http://arabmedicare.com/",
    size: "100%",
    grid: 3,
  },
  {
    name: "Hospitals Magazine",
    pageLink: "Hospitals Magazine",
    type: "MEDIA PARTNER",
    image: "hospitalmagazine.png",
    text: [``],
    link: "https://hospitalsmagazine.com/",
    size: "100%",
    grid: 3,
  },
  {
    name: "Arab Hospital",
    pageLink: "Arab Hospital",
    type: "MEDIA PARTNER",
    image: "arab.png",
    text: [``],
    link: "https://www.thearabhospital.com/",
    size: "100%",
    grid: 3,
  },
  {
    name: "Vydya",
    pageLink: "Vydya",
    type: "MEDIA PARTNER",
    image: "vydya.png",
    text: [
      `Find Providers, Products at vydya.com. Find providers from conventional, complementary and
    alternative care for professional help. Shop for wellness products at store.vydya.com.  Join fellow
    providers, showcase your practice on https://www.vydya.com/`,
    ],
    link: "",
    size: "100%",
    grid: 3,
  },
];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsors);
export const newsponsorsPage = groupByType(newsponsors);

export const news = [
  {
    id: "Hr",
    title: "Feeling unwell and want the day off in Abu Dhabi? Go to HR robot",
    image: "1.png",
    link: "Feeling-unwell-and-want-the-day-off-in-Abu-Dhabi--Go-to-HR-robot",
  },
  {
    id: "Hyperloop",
    title: "Hyperloop systems in UAE, other countries now closer to reality: DP World",
    image: "2.png",
    link: "Hyperloop-systems-in-UAE",
  },
  {
    id: "Advancements",
    title: "The advancements in real world Artificial Intelligence",
    image: "3.png",
    link: "The-advancements-in-real-world-Artificial-Intelligence",
  },
  {
    id: "Bigdata",
    title: "AI and Big Tech should not have control over health",
    image: "4.PNG",
    link: "AI-and-Big-Tech-should-not-have-control-over-health",
  },
  {
    id: "Coop",
    title: "The GCC: A bright future",
    image: "5.PNG",
    link: "The-GCC-A-bright-future",
  },
  {
    id: "AIbots",
    title: "AI bots to boost care at Saudi German Hospital",
    image: "6.PNG",
    link: "AI-bots-to-boost-care-at-Saudi-German-Hospital",
  },
  {
    id: "Transform",
    title: "Digital transformation to drive recovery of GCC retail sector post-Covid-19",
    image: "7.PNG",
    link: "Digital-transformation",
  },
  {
    id: "Cyber",
    title: "Cyber security vital to Dubai's sustained progress: Sheikh Hamdan",
    image: "8.PNG",
    link: "Cyber-security-Sheikh-Hamdan",
  },
];
